import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/1.jpg');

// Filter navigation – here we include an "All" option and an "Industrial" category.
const filters = [
    { label: "All", filter: "*" },
    { label: "Industrial", filter: ".cat-industrial" },
];

// The projects array is created using details from your PDF.
// Replace the placeholder images (pic1, pic2, etc.) with your actual images.
const projects = [
  {
    thumb: require('./../../images/projects/portrait/pic1.jpg'),
    image: require('./../../images/projects/portrait/pic1.jpg'),
    title: 'PRAKHHYAT LAND DEVELOPERS PVT.LTD',
    description: 'Location: PADGHA, BHIVANDI, 2024-25 | Project Area: 4,00,000 & 2,50,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic2.jpg'),
    image: require('./../../images/projects/portrait/pic2.jpg'),
    title: 'MAXWAY REALTY LLP',
    description: 'Location: TALEGAON MIDC, PUNE, 2023-24 | Project Area: 86,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic3.jpg'),
    image: require('./../../images/projects/portrait/pic3.jpg'),
    title: 'SANY HEAVY INDUSTRIES PVT.LTD.',
    description: 'Location: CHAKAN, MIDC, 2023-24 | Project Area: 1,80,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic4.jpg'),
    image: require('./../../images/projects/portrait/pic4.jpg'),
    title: 'ATHARVA LOGISTICS',
    description: 'Location: RANJANGAON MIDC, PUNE, 2019-20 | Project Area: 2,50,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic5.jpg'),
    image: require('./../../images/projects/portrait/pic5.jpg'),
    title: 'ADCL - FAFECO',
    description: 'Location: MOI CHAKAN, PUNE, 2022-23 | Project Area: 1,09,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic6.jpg'),
    image: require('./../../images/projects/portrait/pic6.jpg'),
    title: 'AS LOGISTICS',
    description: 'Location: SANASWADI, PUNE, 2020-21 | Project Area: 75,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic7.jpg'),
    image: require('./../../images/projects/portrait/pic7.jpg'),
    title: 'PARC ROBOTICS SYSTEMS PVT.LTD',
    description: 'Location: SAVARDARI CHAKAN, 2023-24 | Project Area: 60,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic8.jpg'),
    image: require('./../../images/projects/portrait/pic8.jpg'),
    title: 'GROUP ANTOLIN',
    description: 'Location: NIGHOJE, CHAKAN MIDC, PUNE, 2022-23 | Project Area: 72,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic9.jpg'),
    image: require('./../../images/projects/portrait/pic9.jpg'),
    title: 'SARVADNYA INSUSTRIES',
    description: 'Location: NIGHOJE, PUNE, 2020-21 | Project Area: 65,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic10.jpg'),
    image: require('./../../images/projects/portrait/pic10.jpg'),
    title: 'ACESPERA INDUSTRIAL WAREHOUSING PVT.LTD',
    description: 'Location: BHAMBOLI CHAKAN MIDC, PUNE, 2020-21 | Project Area: 39,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic11.jpg'),
    image: require('./../../images/projects/portrait/pic11.jpg'),
    title: 'OM SAI RAM LOGISTICS',
    description: 'Location: NIGHOJE, CHAKAN MIDC, PUNE, 2021-22 | Project Area: 38,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic12.jpg'),
    image: require('./../../images/projects/portrait/pic12.jpg'),
    title: 'OPTIMAL INDUSTRIAL WAREHOUSING PVT.LTD',
    description: 'Location: SAVARDARI CHAKAN MIDC, PUNE, 2021-22 | Project Area: 41,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic13.jpg'),
    image: require('./../../images/projects/portrait/pic13.jpg'),
    title: 'SEVEN STAR INDUSTRIES',
    description: 'Location: AMBETHAN, CHAKAN, 2023-24 | Project Area: 65,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic14.jpg'),
    image: require('./../../images/projects/portrait/pic14.jpg'),
    title: 'MENAR AUTO',
    description: 'Location: AIR LIQUID CHOCK, CHAKAN, 2023-24 | Project Area: 60,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic15.jpg'),
    image: require('./../../images/projects/portrait/pic15.jpg'),
    title: 'RG VENTURE',
    description: 'Location: CHACKAN, MIDC, 2023-24 | Project Area: 89,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic16.jpg'),
    image: require('./../../images/projects/portrait/pic16.jpg'),
    title: 'TRACKON LOGISTICS & INFRASTRUCTURE',
    description: 'Location: RANJANGAON MIDC, PUNE, 2021-22 | Project Area: 3,60,000 SQ.FT',
    filter: 'cat-industrial'
  },
  {
    thumb: require('./../../images/projects/portrait/pic17.jpg'),
    image: require('./../../images/projects/portrait/pic17.jpg'),
    title: 'SILVER BRIGHT',
    description: 'Location: CHAKAN, MIDC, 2024-25 | Project Area: 40,000 SQ.FT',
    filter: 'cat-industrial'
  }
];

class ProjectMasonary extends React.Component {
    
    componentDidMount() {
        function loadScript(src) {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', resolve);
                script.addEventListener('error', reject);
                document.body.appendChild(script);
            });
        };

        loadScript('./assets/js/masonary.js');
    };
    
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner 
                        title="INDUSTRIAL PROJECTS" 
                        pagename="Projects" 
                        bgimage={bnrimg}
                    />
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container">
                            <div className="section-head text-center">
                                <h2 className="text-uppercase">Industrial & Factory Construction Projects</h2>
                                <p>
                                  Bhagirath Narayan Constructions has carried out numerous industrial and factory construction projects for the steel, chemical, and pharma sectors across Maharashtra over the past 9 years. It’s very good work and a testament to our commitment to quality and excellence.
                                </p>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="filter-wrap p-b30 text-center">
                                <ul className="filter-navigation inline-navigation masonry-filter link-style text-uppercase">
                                    <li className="active">
                                        <NavLink to={"#"} data-filter="*" data-hover="All">All</NavLink>
                                    </li>
                                    {filters.map((item, index) => (
                                        <li key={index}>
                                            <NavLink to={"#"} data-filter={item.filter} data-hover={item.label}>
                                              {item.label}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                                {projects.map((item, index) => (
                                    <div key={index} className={`${item.filter} masonry-item col-lg-3 col-md-4 col-sm-6 m-b30`}>
                                        <div className="image-effect-one hover-shadow">
                                            <img src={item.thumb} alt={item.title} />
                                            <div className="figcaption">
                                                <h4>{item.title}</h4>
                                                <p>{item.description}</p>
                                                <NavLink to="/project-detail">
                                                    <i className="link-plus bg-primary" />
                                                </NavLink>
                                            </div>
                                            <a className="mfp-link" href={item.image}>
                                                <i className="fa fa-arrows-alt" />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="m-t50 text-center">
                                <NavLink to={"#"} className="site-button btn-effect">Load More</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    };
};

export default ProjectMasonary;
