import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: 1,
        title: 'PEB Sheds',
        flaticon: 'flaticon-city',
        redirect: 'https://sovereignsteels.com/',
        description: "Transform your spaces with BNC's, we specialize in creating PEB Structures as per requirements."
    },
    {
        count: 2,
        title: 'Logistic Parks',
        flaticon: 'flaticon-paint',
        description: "One of the few Pioneer's in Civil Constructions of industrial sheds with unrealistic Safety, Quality and Speed."
    },
    {
        count: 3,
        title: 'Civil Works',
        flaticon: 'flaticon-crane',
        description: "BNC's stands as your trusted partner in construction projects. We turn your visions into reality with precision and excellence."
    },
    {
        count: 4,
        title: 'Interior',
        flaticon: 'flaticon-decorating',
        description: "Discover the art of interior design with BNC. Our experts craft interiors that reflect your personality, blending aesthetics and functionality seamlessly."
    },
    // {
    //     count: 5,
    //     title: 'Professional Opinion',
    //     flaticon: 'flaticon-chart',
    //     description: "At BNC, we value your trust. Our team provides professional opinions and advice to guide you through every step of your project, ensuring informed decisions."
    // },
    // {
    //     count: 5,
    //     title: 'Accurate Engineering',
    //     flaticon: 'flaticon-sketch',
    //     description: "Precision engineering is the cornerstone of BNC. Our projects are built with accuracy and expertise, guaranteeing lasting quality."
    // },

    {
        count: 5,
        title: 'MEP',
        flaticon: 'flaticon-builder',
        description: "BNC is your all-in-one solution. As general builders, we oversee and execute projects from inception to completion, ensuring excellence at every stage."
    },
    {
        count: 6,
        title: 'Fire Safety',
        flaticon: 'flaticon-drill',
        description: "Trust BNC for electrical solutions that power your projects safely and efficiently. We handle electrical systems with expertise and care."
    },
    // {
    //     count: 9,
    //     title: 'Refurbishment',
    //     flaticon: 'flaticon-art-and-design',
    //     description: "Experience the art of refurbishment with BNC. We breathe new life into spaces, combining creativity and functionality to transform interiors."
    // }
]

var img1 = require('./../../images/background/bg-5.png');
var img2 = require('./../../images/background/line.png');

class OurServices3 extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white mobile-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-center">
                                <div className="mt-separator">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary"></span> Services</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                {services.map((item, index) => (
                                    <div key={index} className="col-md-4 col-sm-6">
                                        <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-white">
                                            <div className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-primary  scale-in-center bg-moving" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                                <span className="icon-cell text-secondry"><i className={item.flaticon} /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">{item.title}</h4>
                                                <p>{item.description}</p>
                                                <NavLink
                                                    to={item?.redirect || "/services"}
                                                    className="site-button-link"
                                                    data-hover="Read More"
                                                    target={item?.redirect ? "_blank" : null}
                                                    onClick={event => {
                                                        if (item?.redirect?.startsWith("http")) {
                                                            event.preventDefault();
                                                            window.open(item.redirect, '_blank');
                                                        }
                                                    }}
                                                >
                                                    Read More <i className="fa fa-angle-right arrow-animation" />
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default OurServices3;