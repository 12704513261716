import React, { createElement } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import GoogleMapReact from 'google-map-react';
import { click } from '@testing-library/user-event/dist/click';
var bnrimg = require('./../../images/banner/4.jpg');

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class DownlaodBro extends React.Component {
    render() {

        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Putting a plan to action, to assure your satisfaction!" pagename="Downlaod Brocure" bgimage={bnrimg} />

                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        {/* LOCATION BLOCK*/}
                        <div className="container">
                            {/* GOOGLE MAP & CONTACT FORM */}
                            <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row">
                                    <div className="col-md-8 col-sm-6">
                                        <h4>Download Our Brochure</h4>
                                        <p>
                                            Elevate your construction experience with BNC Construction. Our comprehensive brochure provides detailed insights into our services, ongoing projects, and commitment to quality. Explore how we can partner with you to bring your vision to life through our innovative construction solutions.
                                        </p>
                                        <h5>What You'll Find Inside:</h5>
                                        <ul>
                                            <li>Overview of Our Services: Detailed descriptions of our industrial, commercial, and turnkey construction services.</li>
                                            <li>Project Portfolio: Showcase of our completed and ongoing projects highlighting our expertise and commitment to excellence.</li>
                                            <li>Core Values: An insight into our company's mission, culture, and dedication to quality.</li>
                                            <li>Client Testimonials: Hear from our satisfied clients about their experiences working with us.</li>
                                        </ul>
                                        <p>
                                            Ready to Get Started? Click the link below to download your copy of the BNC Construction brochure and discover how we can build a stronger future together.
                                        </p>

                                        <div className="appint-btn">
                                            <a
                                                href="/download/BNConstruction_Profile.pdf"
                                                download
                                                className="site-button"
                                                onClick={(e) => {
                                                    e.preventDefault(); // Prevent default behavior of just navigating to the href
                                                    const link = document.createElement('a');
                                                    link.href = '/download/BNConstruction_Profile.pdf';
                                                    link.download = 'BNConstruction_Profile.pdf';
                                                    document.body.appendChild(link);
                                                    link.click();
                                                    document.body.removeChild(link);
                                                }}
                                            >
                                                Download Brochure
                                            </a>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-sm-6">
                                        <div className="contact-info m-b30">
                                            {/* TITLE START */}
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Contact</span> Info</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="bg-dark p-a20 text-white">
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                        <p>8263-835-149</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Email address</h5>
                                                        <p>info@bnconstructions.com</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left">
                                                    <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Address info</h5>
                                                        <p>#3,4, BIZHUB , Pimpri , Pune -411018</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gmap-outline">
                                <div style={{ height: '400px', width: '100%' }}>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.815792463252!2d73.80374587473764!3d18.62735336590459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b96183dee6c7%3A0xaa68e0038b3e3283!2sBIZHUB!5e0!3m2!1sen!2sin!4v1696065544724!5m2!1sen!2sin"
                                        width="100%"
                                        height="400"
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>


                <Footer />

            </>
        );
    };
};

export default DownlaodBro;