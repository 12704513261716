import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/1.jpg');

const clients = [
  {
    thumb: require('./../../images/clients/pic1.jpg'),
    image: require('./../../images/clients/pic1.jpg'),
    name: 'Client List 1',
    description: 'Our collaboration with Clients resulted in innovative projects that pushed industry standards and showcased our commitment to quality and excellence.',
  },
  {
    thumb: require('./../../images/clients/pic2.jpg'),
    image: require('./../../images/clients/pic2.jpg'),
    name: 'Client List 2',
    description: 'Working with Our Client has been a rewarding experience as we delivered tailored solutions that addressed their unique business needs.',
  }
];

const clientList = [
  {
    name: 'Maestrotech Systems Pvt. Ltd.',
    description: 'A technology-driven company focusing on operational productivity and industrial solutions. We collaborate on advanced engineering support, process optimization, and more.'
  },
  {
    name: 'Forbes Marshall',
    description: 'A leader in steam engineering and control instrumentation solutions, known for helping industries optimize processes.'
  },
  {
    name: 'Sany',
    description: 'A global heavy equipment manufacturer specializing in construction machinery, cranes, and excavators. We ensure seamless deployment of their machinery in infrastructure projects.'
  },
  {
    name: 'Fluid Controls',
    description: 'A company dedicated to high-quality fluid and gas control systems. We integrate their solutions into complex engineering projects.'
  },
  {
    name: 'Pyrotek',
    description: 'A global engineering company providing materials and solutions primarily for aluminum production and other industrial sectors.'
  },
  {
    name: 'Shaw Toyota',
    description: 'A Toyota dealership known for reliable automotive sales and service. We assist with facility enhancements and infrastructure solutions.'
  },
  {
    name: 'Ultra Construction',
    description: 'A construction-focused company offering diverse civil engineering and building services. We partner on joint construction ventures.'
  },
  {
    name: 'Ashish Traders',
    description: 'A trading firm specializing in sourcing and distributing industrial or consumer products. We collaborate on supply chain management and procurement.'
  },
  {
    name: 'Rajmudra (Group of Companies)',
    description: 'A conglomerate with interests spanning real estate, construction, or trading. We tailor solutions for their diverse business segments.'
  },
  {
    name: 'Atharva Biopharma',
    description: 'A biopharmaceutical company involved in research, development, or distribution of healthcare products. We build specialized facilities meeting regulatory standards.'
  },
  // ... Add more clients as needed ...
];

class Clients extends React.Component {

  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    };

    loadScript('./assets/js/masonary.js');
  }

  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner title="Our Clients" pagename="Client Gallery" bgimage={bnrimg} />

          {/* Our Work Section */}
          <div className="section-full p-tb40">
            <div className="container">
              <div className="section-head text-center">
                <h2 className="title">Our Work</h2>
                <p>
                  We are proud to have partnered with some of the most esteemed names in the industry. Our work reflects our dedication to excellence and innovation, and each client story is a testament to our commitment to delivering outstanding results and building lasting relationships.
                </p>
              </div>
            </div>
          </div>

          {/* Client Gallery Section */}
          <div className="section-full p-tb80 bg-gray inner-page-padding">
            <div className="container">
              <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                {clients.map((item, index) => (
                  <div key={index} className="masonry-item col-lg-4 col-md-6 col-sm-12 m-b30">
                    <div className="client-card hover-shadow">
                      <div className="mt-box">
                        <div className="mt-thum-bx img-center-icon mt-img-overlay2">
                          <img src={item.thumb} alt={item.name} />
                          <div className="overlay-bx">
                            <div className="overlay-icon">
                              <NavLink to={"/client-detail"}>
                                <i className="fa fa-external-link mt-icon-box-xs" />
                              </NavLink>
                              <a className="mfp-link" href={item.image}>
                                <i className="fa fa-arrows-alt mt-icon-box-xs" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-info bg-white p-a30">
                        <h4 className="mt-title m-b10 m-t0" data-hover={item.name}>{item.name}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* Clients Accordion Section */}
              <div className="section-full inner-page-padding">
                <div className="container">
                  <div className="faq-1 bg-gray">
                    {/* TITLE START */}
                    <div className="section-head">
                      <div className="separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-uppercase sep-line-one">
                            Our Clients
                          </h2>
                        </div>
                      </div>
                      <p className="text-center">
                        We are proud to have partnered with some of the most esteemed names in the industry. Below is a list of our valued clients, each reflecting our commitment to delivering outstanding results and building lasting relationships.
                      </p>
                    </div>
                    {/* TITLE END */}

                    {/* Accordion */}
                    <div className="mt-accordion acc-bg-white" id="accordionClients">
                      {clientList.map((client, index) => (
                        <div className="panel mt-panel" key={index}>
                          <div className="acod-head">
                            <h4 className="acod-title">
                              {/* Toggle link for each client */}
                              <a
                                data-toggle="collapse"
                                href={`#collapseClient${index}`}
                                className={index === 0 ? '' : 'collapsed'}
                                data-parent="#accordionClients"
                              >
                                {client.name}
                                <span className="indicator">
                                  <i className="fa fa-plus" />
                                </span>
                              </a>
                            </h4>
                          </div>
                          {/* Collapsible body */}
                          <div
                            id={`collapseClient${index}`}
                            className={`acod-body collapse ${index === 0 ? 'in' : ''}`}
                          >
                            <div className="acod-content p-tb15">
                              <p>{client.description}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* End Accordion */}
                  </div>
                </div>
              </div>

              <div className="m-t50 text-center">
                <NavLink to={"#"} className="site-button btn-effect">Load More</NavLink>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Clients;
