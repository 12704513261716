import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/1.jpg');

const certificates = [
  {
    thumb: require('./../../images/certificates/certi1.jpg'),
    image: require('./../../images/certificates/certi1.jpg'),
    title: 'ISO 9001:2015 Certification',
    certificateNo: 'IN56971A',
    description: 'This certificate verifies that the Quality Management System of BHAGIRATH NARAYAN CONSTRUCTIONS conforms to the ISO 9001:2015 standard, covering Residential, Commercial, and Industrial Constructions. It confirms a commitment to quality processes and continuous improvement.',
  },
  {
    thumb: require('./../../images/certificates/certi2.jpg'),
    image: require('./../../images/certificates/certi2.jpg'),
    title: 'ISO 14001:2015 Certification',
    certificateNo: 'IN56971B',
    description: 'This certificate confirms that BHAGIRATH NARAYAN CONSTRUCTIONS meets the requirements of the ISO 14001:2015 standard for Environmental Management. It covers various construction operations with a focus on reducing environmental impacts.',
  },
  {
    thumb: require('./../../images/certificates/certi3.jpg'),
    image: require('./../../images/certificates/certi3.jpg'),
    title: 'ISO 45001:2018 Certification',
    certificateNo: 'IN56971C-1',
    description: 'This certificate demonstrates that BHAGIRATH NARAYAN CONSTRUCTIONS complies with the ISO 45001:2018 standard for Occupational Health & Safety Management. It emphasizes safe working practices across Residential, Commercial, and Industrial projects.',
  }
];

class CertificatesGallery extends React.Component {

  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    };

    loadScript('./assets/js/masonary.js');
  }

  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner title="Our Certifications" pagename="Certificate Gallery" bgimage={bnrimg} />
          
          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 bg-gray inner-page-padding">
            <div className="container">
              {/* GALLERY CONTENT START */}
              <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                {certificates.map((item, index) => (
                  <div key={index} className="masonry-item col-lg-4 col-md-6 col-sm-12 m-b30">
                    <div className="certificate-card hover-shadow">
                      <div className="mt-box">
                        <div className="mt-thum-bx img-center-icon mt-img-overlay2">
                          <img src={item.thumb} alt={item.title} />
                          <div className="overlay-bx">
                            <div className="overlay-icon">
                              <NavLink to={"/certificate-detail"}>
                                <i className="fa fa-external-link mt-icon-box-xs" />
                              </NavLink>
                              <a className="mfp-link" href={item.image}>
                                <i className="fa fa-arrows-alt mt-icon-box-xs" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-info bg-white p-a30">
                        <h4 className="mt-title m-b10 m-t0" data-hover={item.title}>{item.title}</h4>
                        <p className="m-b5"><strong>Certificate No:</strong> {item.certificateNo}</p>
                        <p className="m-b0">{item.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* GALLERY CONTENT END */}
              <div className="m-t50 text-center">
                <NavLink to={"#"} className="site-button btn-effect">Load More</NavLink>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>
        <Footer />
      </>
    );
  }
}

export default CertificatesGallery;
