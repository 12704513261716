import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import GoogleMapReact from 'google-map-react';

var bnrimg = require('./../../images/banner/4.jpg');

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class ContactUs extends React.Component {
    render() {
       
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Putting a plan to action, to assure your satisfaction!" pagename="Contact Us" bgimage={bnrimg} />

                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        {/* LOCATION BLOCK*/}
                        <div className="container">
                            {/* GOOGLE MAP & CONTACT FORM */}
                            <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row">
                                    <div className="col-md-8 col-sm-6">
                                        <form className="contact-form cons-contact-form" method="post" action="form-handler.php" encType="multipart/form-data">
                                            <div className="contact-one m-b30">
                                                {/* TITLE START */}
                                                <div className="section-head">
                                                    <div className="mt-separator-outer separator-left">
                                                        <div className="mt-separator">
                                                            <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Get</span> In touch</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* TITLE END */}
                                                <div className="form-group">
                                                    <input name="username" type="text" required className="form-control" placeholder="Name" />
                                                </div>
                                                <div className="form-group">
                                                    <input name="email" type="text" required className="form-control" placeholder="Email" />
                                                </div>
                                                <div className="form-group">
                                                    <input name="contactNumber" type="tel" required className="form-control" placeholder="Contact Number" />
                                                </div>
                                                <div className="form-group">
                                                    <select name="designation" className="form-control" required>
                                                        <option value="" disabled>Select Designation</option>
                                                        <option value="Engineer">Engineer</option>
                                                        <option value="Site Engineer">Site Engineer</option>
                                                        <option value="Project Manager">Project Manager</option>
                                                        <option value="Accountant">Accountant</option>
                                                        <option value="Project Coordinator">Project Coordinator</option>
                                                        <option value="Project Manager">Project Manager</option>
                                                        <option value="Sr. Engineer">Sr. Engineer</option>
                                                        <option value="Jr. Engineer">Jr. Engineer</option>
                                                        <option value="Trainee Engineer">Trainee Engineer</option>
                                                        <option value="Purchase">Purchase</option>
                                                        <option value="Maintenance">Maintenance</option>
                                                        <option value="Service">Service</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="resume">Attach Resume (PDF, DOCX, or PPT, Max 3MB)</label>
                                                    <input name="resume" type="file" id="resume" accept=".pdf, .docx, .ppt" className="form-control-file" required />
                                                </div>
                                                <div className="form-group">
                                                    <input name="previousCompany" type="text" required className="form-control" placeholder="Previous Company" />
                                                </div>
                                                <div className="form-group">
                                                    <input name="experience" type="number" step={0.01} required className="form-control" placeholder="Experience in Years" />
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" rows={4} required className="form-control" placeholder="Message"></textarea>
                                                </div>
                                                <div className="text-right">
                                                    <button name="submit" type="submit" value="Submit" className="site-button btn-effect">Submit</button>
                                                </div>
                                            </div>
                                        </form>


                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="contact-info m-b30">
                                            {/* TITLE START */}
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Contact</span> Info</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="bg-dark p-a20 text-white">
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                        <p>8263-835-149</p>
                                                    </div>
                                                </div>
                                                
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Email address</h5>
                                                        <p>info@bnconstructions.com</p>
                                                        <p>hr@bnconstructions.com</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left">
                                                    <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Address info</h5>
                                                        <p>#3,4, BIZHUB , Pimpri , Pune -411018</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gmap-outline">
                                <div style={{ height: '400px', width: '100%' }}>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.815792463252!2d73.80374587473764!3d18.62735336590459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b96183dee6c7%3A0xaa68e0038b3e3283!2sBIZHUB!5e0!3m2!1sen!2sin!4v1696065544724!5m2!1sen!2sin"
                                        width="100%"
                                        height="400"
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>


                <Footer />

            </>
        );
    };
};

export default ContactUs;